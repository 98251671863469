$(document).ready(function () {

    $('.reviews_slider_for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.reviews_slider_nav'
    });
    $('.reviews_slider_nav').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        asNavFor: '.reviews_slider_for',
        nextArrow: $('.reviews_slider_arrows .icon-right-open'),
        prevArrow: $('.reviews_slider_arrows .icon-left-open'),
        centerMode: true,
        focusOnSelect: true,
        responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    $('.questions_item_answer').hide();

    $('.questions_item_question').click(function (e) {

        e.preventDefault();

        $(this).find('.icon-help-circled-alt').toggleClass('active_icon_help');

        var thisIconOpen = $(this).find('.icon-down-open');
        var thisIconUp = $(this).find('.icon-up-open');

        if (thisIconOpen.hasClass('icon-down-open')) {
            thisIconOpen.removeClass('icon-down-open');
            thisIconOpen.addClass('icon-up-open');
        } else if (thisIconUp.hasClass('icon-up-open')) {
            thisIconUp.removeClass('icon-up-open');
            thisIconUp.addClass('icon-down-open');
        }

        $(this).toggleClass('active_icon');

        $(this).next().toggle('slow');
    });

});